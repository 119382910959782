.App {
  text-align: center;
  background-color: #0C0C0E; /* 전체 배경색을 검은색으로 설정 */
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-header,
.App-footer {
  padding: 20px;
  background-color: #0C0C0E;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
