.top-banner h1 {
  font-size: 1.9rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.top-banner h1:nth-child(2) {
  padding-bottom: 40px;
}

.top-banner img {
  width: 80%;
}

.top-banner {
  padding-top: 40px;
}

.scroll-indicator img {
  width: 5%;
  padding-bottom: 120px;
}

.feature-container {
  margin-bottom: 120px;
}

.feature-container h1,
.feature-container h2 {
  margin: 0;
  padding: 0;
}

.feature-container h1 {
  font-size: 1.6rem;
  font-weight: bold;
}

.feature-container h1:nth-child(2) {
  padding-bottom: 40px;
}

.feature-container img {
  width: 50%;
}

.download-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 8px; /* 버튼의 모서리를 둥글게 설정 */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.download-button:hover {
  background-color: #e0e0e0; /* 버튼에 마우스를 올렸을 때의 배경색 */
}

.icon-container {
  margin-top: 20px;
}

.icon {
  width: 100px;
  height: 100px;
}

.bottom-banner h1 {
  font-size: 1.9rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.bottom-banner h1:nth-child(2) {
  padding-bottom: 40px;
}

.bottom-banner {
  margin-bottom: 120px;
}

.bottom-banner img {
  width: 80%;
}
