.footer {
  background-color: #0C0C0E;
  color: #b3b3b3;
  padding: 20px 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 왼쪽 정렬로 변경 */
  font-size: 14px;
  width: 80%; /* 화면 전체 너비를 차지하도록 설정 */
}

.footer-section {
  margin-bottom: 20px;
  text-align: left;
}

.footer-section h4 {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.footer-section p {
  margin: 5px 0;
  color: #b3b3b3;
  text-decoration: none;
  font-size: 1rem;
}

.footer-section a {
  color: #b3b3b3;  /* 링크 텍스트 색상 */
  text-decoration: none;  /* 밑줄 제거 */
  font-size: 1rem;
}

.footer-section a:hover {
  color: #ffffff;  /* hover 시에도 텍스트 색상 유지 */
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #333;
  padding-top: 10px;
  width: 100%;
  text-align: left;
}

.footer-bottom p {
  font-size: 1rem;
  padding-bottom: 100px;
}
