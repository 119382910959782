.hamburger-menu {
  display: inline-block;
  background-color: #0C0C0E; /* 배경색을 #0C0C0E로 변경 */
  padding: 20px 10%;
  align-items: flex-start; /* 왼쪽 정렬로 변경 */
  font-size: 14px;
  width: 80%;
}

.hamburger-icon {
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger-icon .bar {
  width: 100%;
  height: 2px;
  background-color: #b3b3b3; /* 햄버거 아이콘의 색상을 흰색으로 설정 */
  transition: all 0.3s ease;
}

.hamburger-icon.open .bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open .bar:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

.menu {
  display: none;
  flex-direction: column;
  background-color: #0C0C0E; /* 메뉴의 배경색도 #0C0C0E로 설정 */
  padding: 10px;
  margin-top: 10px; /* 메뉴가 햄버거 아이콘 아래에 나타나도록 간격 추가 */
  margin-left: 0; /* 메뉴를 왼쪽으로 정렬 */
}

.menu.open {
  display: flex;
  gap: 10px;
}

.menu a {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  color: white; /* 메뉴 텍스트 색상을 흰색으로 설정 */
  padding: 8px 16px;
  transition: background-color 0.3s;
}

.menu a:nth-child(2) {
  background-color: white; /* Menu 2의 배경을 하얀색으로 설정 */
  color: black; /* Menu 2의 폰트 색상을 검은색으로 설정 */
  border-radius: 8px; /* Menu 2의 버튼에 radius 추가 */
}

.menu a:nth-child(2):hover {
  background-color: #e0e0e0; /* Menu 2의 버튼에 마우스를 올렸을 때의 배경색 */
}
